<template>
	<!--新增合同-->
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="contract-manage-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			ref="contractForm"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="协议类型" prop="type">
						<Select
							v-model="formData.type"
							:disabled="entry === 'view'"
							clearable
							placeholder="请选择协议类型"
						>
							<Option :value="1">主协议</Option>
							<Option :value="2">补充协议</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目负责科室">
						<Select
							v-model="formData.department"
							:disabled="entry === 'view'"
							filterable
							clearable
							placeholder="请选择项目负责科室"
						>
							<Option
								:value="depart.name"
								v-for="depart in departList"
								:key="depart.id"
								>{{ depart.name }}</Option
							>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目负责人" prop="piName">
						<Input
							:disabled="entry === 'view'"
							:maxlength="200"
							v-model.trim="formData.piName"
							placeholder="请输入项目负责人"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="手机号" prop="piMobile">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入手机号"
							:maxlength="11"
							v-model.trim="formData.piMobile"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目编号">
						<Input
							:disabled="entry === 'view'"
							v-model.trim="formData.projectNo"
							:maxlength="200"
							placeholder="请输入项目编号"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目类别">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入项目类别"
							:maxlength="200"
							v-model.trim="formData.projectType"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="项目名称" prop="projectName">
						<Input
							:disabled="entry === 'view'"
							type="textarea"
							:row="3"
							:maxlength="500"
							v-model.trim="formData.projectName"
							placeholder="请输入项目名称"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="申办方（请填写全称）" prop="smo">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入申办方全称"
							v-model.trim="formData.smo"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="CRO">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入CRO"
							v-model.trim="formData.cro"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="合同签订时间" prop="signTime">
						<DatePicker
							type="date"
							:disabled="entry === 'view'"
							placeholder="请选择合同签订时间"
							clearable
							style="width: 100%"
							v-model="formData.signTime"
							format="yyyy-MM-dd"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="合同经费总额（元）" prop="totalCost">
						<InputNumber
							:disabled="entry === 'view'"
							placeholder="请输入合同经费总额（元）"
							:precision="2"
							:min="0"
							:max="99999999.99"
							:active-change="false"
							style="width: 100%"
							v-model="formData.totalCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="研究者观察费（元）" prop="piResearchCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入研究者观察费（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.piResearchCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="受试者检查费（元）" prop="subjectCheckCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入受试者检查费（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.subjectCheckCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="受试者补偿费（元）" prop="subjectCompensateCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入受试者补偿费（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.subjectCompensateCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="牵头费（元）" prop="leadCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入牵头费（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.leadCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="药品管理费（元）" prop="medicineManageCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入药品管理费（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.medicineManageCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="档案管理费（元）" prop="fileManageCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入档案管理费（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.fileManageCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="医院管理费总额（元）" prop="hospitalManageCost">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入医院管理费总额（元）"
							:precision="2"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.hospitalManageCost"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="24">
					<Row :gutter="32">
						<Col span="12">
							<FormItem label="其他费用（元）">
								<InputNumber
									:min="0"
									disabled
									placeholder="请输入其他费用（元）"
									:precision="2"
									style="width: 100%"
									:max="99999999.99"
									:active-change="false"
									v-model="formData.otherCost"
								></InputNumber>
							</FormItem>
						</Col>
						<Col span="12">
							<Button
								style="margin-top: 24px"
								@click="handleAddOther"
								:disabled="entry === 'view'"
								v-if="formData.otherFeeDetailList.length < 50"
								>添加</Button
							>
						</Col>
						<Col span="16" class="cost-wrap">
							<Row
								v-for="(item, index) in formData.otherFeeDetailList"
								:key="index"
							>
								<Col span="18">
									<Row :gutter="16">
										<Col span="12">
											<FormItem
												label="费用名称"
												class="other-label"
												:prop="'otherFeeDetailList.' + index + '.feeName'"
												:rules="{
													required: true,
													message: '费用名称不能为空',
													trigger: 'blur'
												}"
											>
												<Input
													:disabled="entry === 'view'"
													placeholder="请输入费用名称"
													v-model.trim="item.feeName"
													:maxlength="20"
												/>
											</FormItem>
										</Col>
										<Col span="12">
											<FormItem
												label="费用金额（元）"
												class="other-label"
												:prop="'otherFeeDetailList.' + index + '.expenses'"
												:rules="{
													required: true,
													type: 'number',
													message: '费用金额不能为空',
													trigger: 'change'
												}"
											>
												<InputNumber
													:min="0"
													:disabled="entry === 'view'"
													placeholder="请输入费用金额（元）"
													:precision="2"
													style="width: 100%"
													:max="999999.99"
													:active-change="false"
													@on-change="handleChangeNum"
													v-model="item.expenses"
												></InputNumber>
											</FormItem>
										</Col>
									</Row>
								</Col>
								<Col span="6">
									<Button
										style="margin-left: 26px; margin-top: 24px"
										:disabled="entry === 'view'"
										@click="handleDelOther(item, index)"
										>删除</Button
									>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col span="12">
					<FormItem label="预计受试者例数" prop="expectedSubjectNum">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入预计受试者例数"
							:precision="0"
							style="width: 100%"
							:max="99999999.99"
							:active-change="false"
							v-model="formData.expectedSubjectNum"
						></InputNumber>
					</FormItem>
				</Col>
			</Row>
			<Row style="padding-bottom: 50px" :gutter="32" v-if="visible">
				<Col span="24">
					<FormItem label="上传签署合同扫描件" prop="contractAttachmentList">
						<Upload
							action="/"
							ref="upload"
							multiple
							:class="{ noDelBtn: entry === 'view' }"
							:disabled="entry === 'view'"
							:before-upload="file => handleBeforeUpload(file)"
							:on-error="onError"
							:on-remove="onRemove"
							:on-preview="handlePreview"
							:default-file-list="formData.contractAttachmentList"
						>
							<Button :loading="uploadLoading" :disabled="entry === 'view'">{{
								uploadLoading ? "上传中..." : "上传附件"
							}}</Button>
						</Upload>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button
				type="primary"
				@click="onSubmit"
				v-if="entry !== 'view'"
				:loading="uploadLoading"
				>确定</Button
			>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/contract"
import { mapState } from "vuex"
import moment from "moment"
import { formDataToMD5 } from "@/utils/util"

const { apiGetContractDetail, uploadContractFile, addContract, editContract } =
	api

export default {
	name: "Add",
	props: {
		visible: {},
		projectId: {
			type: String,
			default: undefined
		},
		contractId: {},
		entry: {},
		departList: {},
		projectInfo: {},
		apiUrlObj: {
			type: Object,
			default: () => ({ apiGetContractDetail })
		}
	},
	data() {
		return {
			loading: false,
			uploadLoading: false,
			title: "",
			formData: {
				contractAttachmentList: [],
				totalCost: null,
				piResearchCost: null,
				subjectCheckCost: null,
				subjectCompensateCost: null,
				leadCost: null,
				medicineManageCost: null,
				fileManageCost: null,
				hospitalManageCost: null,
				otherCost: 0,
				expectedSubjectNum: null,
				otherFeeDetailList: []
			},
			rules: {
				type: {
					required: true,
					type: "number",
					message: "协议类型不能为空",
					trigger: "blur change"
				},
				piName: {
					required: true,
					message: "项目负责人不能为空",
					trigger: "blur"
				},
				piMobile: [
					{
						required: true,
						message: "手机号不能为空",
						trigger: "blur"
					},
					{
						...this.$rules.mobileNumber,
						message: "手机号码格式错误",
						trigger: "blur"
					}
				],
				projectName: {
					required: true,
					message: "项目名称不能为空",
					trigger: "blur"
				},
				smo: {
					required: true,
					message: "申办方不能为空",
					trigger: "blur"
				},
				signTime: {
					required: true,
					type: "date",
					message: "合同签订时间不能为空",
					trigger: "change blur"
				},
				totalCost: [
					{
						required: true,
						type: "number",
						message: "合同经费总额(元）不能为空",
						trigger: "change"
					}
				],
				piResearchCost: [
					{
						required: true,
						type: "number",
						message: "研究者观察费(元）不能为空",
						trigger: "change"
					}
				],
				subjectCheckCost: [
					{
						required: true,
						type: "number",
						message: "受试者检查费(元）不能为空",
						trigger: "change"
					}
				],
				subjectCompensateCost: [
					{
						required: true,
						type: "number",
						message: "受试者补偿费(元）不能为空",
						trigger: "change"
					}
				],
				leadCost: [
					{
						required: true,
						type: "number",
						message: "牵头费(元）不能为空",
						trigger: "change"
					}
				],
				medicineManageCost: [
					{
						required: true,
						type: "number",
						message: "药品管理费(元）不能为空",
						trigger: "change"
					}
				],
				fileManageCost: [
					{
						required: true,
						type: "number",
						message: "档案管理费(元）不能为空",
						trigger: "change"
					}
				],
				hospitalManageCost: [
					{
						required: true,
						type: "number",
						message: "医院管理费总额(元）不能为空",
						trigger: "change"
					}
				],
				expectedSubjectNum: [
					{
						required: true,
						type: "number",
						message: "预计受试者例数不能为空",
						trigger: "change"
					}
				],
				contractAttachmentList: {
					required: true,
					type: "array",
					message: "上传文件不能为空",
					trigger: "change blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.uploadLoading = false
				this.$refs.contractForm.resetFields()
				this.formData = {
					...this.projectInfo,
					contractAttachmentList: [],
					otherFeeDetailList: [],
					totalCost: null,
					piResearchCost: null,
					subjectCheckCost: null,
					subjectCompensateCost: null,
					leadCost: null,
					medicineManageCost: null,
					fileManageCost: null,
					hospitalManageCost: null,
					otherCost: 0,
					expectedSubjectNum: null
				}
				this.title = "新增合同"
				if (this.contractId) {
					this.title = this.entry === "view" ? "查看合同" : "修改合同"
					this.$asyncDo(async () => {
						this.loading = true
						const params = {
							contractId: this.contractId
						}
						if (this.projectId) params.projectId = this.projectId
						const res = await this.apiUrlObj.apiGetContractDetail(params)

						if (res) {
							let fileList = res.data ? res.data.contractAttachmentList : []
							if (fileList.length) {
								fileList = fileList.map(item => {
									const file = { ...item }
									file.name = item.filename
									return file
								})
							}
							this.formData = {
								...res.data,
								contractAttachmentList: fileList,
								otherFeeDetailList: res.data.otherFeeDetailList || [],
								otherCost: res.data.otherCost || 0,
								signTime: res.data ? new Date(res.data.signTime) : ""
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		// 添加费用
		handleAddOther() {
			this.formData.otherFeeDetailList.push({
				feeName: "",
				expenses: null
			})
		},
		// 删除费用
		handleDelOther(child, index) {
			this.formData.otherFeeDetailList.splice(index, 1)
			if (
				this.formData.otherFeeDetailList &&
				this.formData.otherFeeDetailList.length
			) {
				this.formData.otherCost = this.formData.otherFeeDetailList
					.map(item => Number(item.expenses))
					.reduce((total, value) => total + value)
			} else {
				this.formData.otherCost = 0
			}
		},
		// 更改其他费用的金额
		handleChangeNum(val) {
			if (
				this.formData.otherFeeDetailList &&
				this.formData.otherFeeDetailList.length
			) {
				this.formData.otherCost = this.formData.otherFeeDetailList
					.map(item => Number(item.expenses))
					.reduce((total, value) => total + value)
			} else {
				this.formData.otherCost = 0
			}
		},
		handleBeforeUpload(file) {
			this.uploadLoading = true
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, md5Str)
			})
			return false
		},
		// 上传文件
		uploadFile(file, md5Str) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				fd.append("md5", md5Str)
				const res = await uploadContractFile(this.projectId, fd)
				if (res) {
					this.$Message.success("上传成功!")
					const newFile = Object.assign(file, { ...res.data })
					this.formData.contractAttachmentList.push(newFile)
					this.$refs.contractForm.validateField("contractAttachmentList")
				}
				this.uploadLoading = false
			})
		},
		onError(error) {
			this.$Message.error(error)
		},
		onRemove(file) {
			this.formData.contractAttachmentList =
				this.formData.contractAttachmentList.filter(item => {
					if (file.id) {
						return item.id !== file.id
					}
					return item.uid !== file.uid
				})
			this.$refs.contractForm.validateField("contractAttachmentList")
		},
		// 下载
		handlePreview(file) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${file.attachmentId}?token=${this.$store.state.user.token}`
			)
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onSubmit() {
			this.$refs.contractForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.contractId) {
							res = await editContract({
								...this.formData,
								signTime: moment(this.formData.signTime).format("YYYY-MM-DD"),
								projectId: this.projectId
							})
						} else {
							res = await addContract({
								...this.formData,
								signTime: moment(this.formData.signTime).format("YYYY-MM-DD"),
								projectId: this.projectId
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style lang="less">
.contract-manage-wrapper {
	margin-bottom: 20px;
	.ivu-select-input[disabled],
	.ivu-input[disabled],
	.ivu-input-number-input[disabled] {
		color: #333;
	}
	.ivu-input-number-input::placeholder {
		color: #c5c8ce;
	}
	.noDelBtn {
		.ivu-upload-list-remove {
			display: none;
		}
	}
	.cost-wrap .other-label {
		.ivu-form-item-label {
			color: #666;
			font-weight: 400;
		}
	}
}
</style>
